export const experiences = [

  {
    id: 0,
    img: './images/epfl_logo.png',
    role: 'Research Scholar',
    company: 'École Polytechnique Fédérale de Lausanne (EPFL)',
    date: 'Sept 2023 - Present',
    desc: `
    Presently working on scalable equivariant generative models for graph data, 
    emphasizing their utility in biological applications, while also contributing 
    to a versatile graph framework compatible with various architectures and data 
    augmentation techniques, including LLMs, for diverse graph tasks.
    `
  },
  {
    id: 0,
    img: './images/Microsoft_logo.svg',
    role: 'Predoctoral Researcher (Research Fellow)',
    company: 'Microsoft Research Lab India',
    date: 'July 2021 - July 2023',
    desc: `
    Worked on problems related to: Node Classification, 
    Representation Learning in Graphs, Multi-Behavior Recommendation, 
    Spectral Methods and Graph Topology. Published papers at 
    ICLR GTRL 2022 (Spotlight), ECML PKDD 2022, KDD MLG 2023 and at NeurIPS 2023.
    `
  },
  {
    id: 1,
    img: './images/mila.png',
    role: 'Research Intern',
    company: 'Mila - Quebec AI Institute',
    date: 'Jan 2022 - June 2022',
    desc: `Extended Energy-based GFlow Nets to discover novel drugs and vaccines.
    Published papers at ICLR 2022, and at NeurIPS HiLL 2022.`
  },
  {
    id: 2,
    img: './images/s20_logo.png',
    role: 'Intern ',
    company: 'S20.AI',
    date: 'Jan 2021 - July 2021',
    desc: `
    Led the data-science team in building two new computer vision based 
    products that increased the average revenue by about 45%. 
    Worked on developing a SAAS platform (Plasma) for MLOps.`
  },
  {
    id: 3,
    img: './images/FU.jpeg',
    role: 'Research Intern',
    company: 'Freie Universität',
    date: 'June 2021 - Oct 2021',
    desc: `Created an LSTM based Ladder Network for imitating biological 
    behaviors (used guppy fish as test subjects). Worked on developing an 
    entropy based Explainable AI method.`
  },
  {
    id: 4,
    img: './images/GSOC.png',
    role: 'Student Developer',
    company: 'Google Summer of Code',
    date: 'June 2021 - Oct 2021',
    desc: `
    Developed a machine learning based application that reduced the amount of malicious sites that could potentially 
    be accessed, for the Cloud Native Computing Foundation (CNCF)`
  },
  {
    id: 5,
    img: './images/IISc.jpg',
    role: 'Research Intern',
    company: 'Indian Institute of Science (IISc)',
    date: 'June 2019 - Aug 2019',
    desc: `
    Investigated the problem of developing auction mechanisms for supply 
    and demand-side management in multi-microgrid (smartgrid) 
    networks as a multi-agent Markov Decision Process (MDP).`
  }
]

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import ReactGA from "react-ga4";

// import TagManager from 'react-gtm-module'
 
// const tagManagerArgs = {
//     gtmId: 'G-14PR3VXM3E'
// }

const TRACKING_ID = "G-14PR3VXM3E"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.send({ hitType: "pageview", page:window.location.pathname
});

// TagManager.initialize(tagManagerArgs);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


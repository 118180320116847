export const projects = [
  {
    id: 0,
    title:
      'FiGURe: Simple and Efficient Unsupervised Node Representations with Filter Augmentations',
    description:
      'Unsupervised node representations learnt using contrastive learning-based methods have shown good performance on downstream tasks. However, these methods rely on augmentations that mimic low-pass filters, limiting their performance on tasks requiring different eigen-spectrum parts. This paper presents a simple filter-based augmentation method to capture different parts of the eigen-spectrum. We show significant improvements using these augmentations. Further, we show that sharing the same weights across these different filter augmentations is possible, reducing the computational load. In addition, previous works have shown that good performance on downstream tasks requires high dimensional representations. Working with high dimensions increases the computations, especially when multiple augmentations are involved. We mitigate this problem and recover good performance through lower dimensional embeddings using simple random Fourier feature projections. Our method, FiGURe, achieves an average gain of up to 4.4%, compared to the state-of-the-art unsupervised models, across all datasets in consideration, both homophilic and heterophilic.',
    image: './images/Figure_image.jpeg',
    authors: `Authors: Chanakya Ekbote* , Ajinkya Deshpande*, Arun Iyer, Ram Bairi, Sundarajan Sellamanickam`,
    tags: [
      'NeurIPS 2023', 
      'KDD MLG 2023',
      'Graph Neural Networks',
      'Unsupervised Representation Learning',
      'Graph Filters'
    ],
    code: 'https://github.com/microsoft/figure',
    poster:
      'https://drive.google.com/file/d/1JSrb3XaKt6lPgSFavn-hyBZQa2v4JaVY/view?usp=drive_link',
    paper: 'https://neurips.cc/virtual/2023/poster/69941'
  },
  {
    id: 1,
    title:
      'A Piece-Wise Polynomial Filtering Approach for Graph Neural Networks',
    description: `Graph Neural Networks (GNNs) exploit signals from node features and the input graph topology to improve node classification task performance. Recently proposed GNNs work across a variety of homophilic and heterophilic graphs. Among these, models relying on polynomial graph filters have shown promise. We observe that polynomial filter models need to learn a reasonably high degree polynomials without facing any over-smoothing effects. We find that existing methods, due to their designs, either have limited efficacy or can be enhanced further. We present a spectral method to learn a bank of filters using a piece-wise polynomial approach, where each filter acts on a different subsets of the eigen spectrum. The approach requires eigendecomposition only for a few eigenvalues at extremes (i.e., low and high ends of the spectrum) and offers flexibility to learn sharper and complex shaped frequency responses with low-degree polynomials. We theoretically and empirically show that our proposed model learns a better filter, thereby improving classification accuracy. Our model achieves performance gains of up to ∼
    6% over the state-of-the-art (SOTA) models while being only ∼
    2x slower than the recent spectral approaches on graphs of sizes up to ∼
    169K nodes.`,
    image: './images/ppgnn.jpeg',
    authors: `Authors: Chanakya Ekbote* , Vijay Lingam*, Manan Sharma*, Rahul Ragesh, Arun Iyer, Ram Bairi, Sundarajan Sellamanickam`,
    tags: [
      'ICLR GTRL (Spotlight) 2022',
      'ECML PKDD 2022',
      'Graph Neural Networks',
      'Graph Representation Learning',
      'Spectral Graph Theory'
    ],
    code: 'https://github.com/microsoft/ppgnn',
    slides:
      'https://drive.google.com/file/d/1iOsTEpjZgixot32cDmjW0ldEb82DnbzG/view?usp=drive_link',
    paper: 'https://link.springer.com/chapter/10.1007/978-3-031-26390-3_25'
  },
  {
    id: 2,
    title:
      'Consistent Training via Energy-Based GFlowNets for Modeling Discrete Joint Distributions',
    description: `Generative Flow Networks (GFlowNets) have demonstrated significant performance improvements for generating diverse discrete objects x given a reward function R(x), indicating the utility of the object and trained independently from the GFlowNet by supervised learning to predict a desirable property y given x. We hypothesize that this can lead to incompatibility between the inductive optimization biases in training R and in training the GFlowNet, potentially leading to worse samples and slow adaptation to changes in the distribution. In this work, we build upon recent work on jointly learning energy-based models with GFlowNets and extend it to learn the joint over multiple variables, which we call Joint Energy-Based GFlowNets (JEBGFNs), such as peptide sequences and their antimicrobial activity. Joint learning of the energy-based model, used as a reward for the GFlowNet, can resolve the issues of incompatibility since both the reward function R and the GFlowNet sampler are trained jointly. We find that this joint training or joint energy-based formulation leads to significant improvements in generating anti-microbial peptides. As the training sequences arose out of evolutionary or artificial selection for high antibiotic activity, there is presumably some structure in the distribution of sequences that reveals information about the antibiotic activity. This results in an advantage to modeling their joint generatively vs. pure discriminative modeling. We also evaluate JEBGFN in an active learning setting for discovering anti-microbial peptides.`,
    authors: `Authors: Chanakya Ekbote, Moksh Jain, Payel Das, Yoshua Bengio`,
    image: './images/jebgfn.jpeg',
    tags: [
      'NeurIPS HiLL, 2022',
      'Generative Flow Networks', 'Drug Discovery', 'Energy-Based Models'],
    poster:
      'https://drive.google.com/drive/u/0/folders/1-j5FZ2smg68yj0Uc9yYJkryPCkfZtW1f',
    paper:
      'https://research.ibm.com/publications/consistent-training-via-energy-based-gflownets-for-modeling-discrete-joint-distributions'
  },
  {
    id: 3,
    title: 'Biological Sequence Design with GFlowNets',
    description: `Design of de novo biological sequences with desired properties, like protein and DNA sequences, often involves an active loop with several rounds of molecule ideation and expensive wet-lab evaluations. These experiments can consist of multiple stages, with increasing levels of precision and cost of evaluation, where candidates are filtered. This makes the diversity of proposed candidates a key consideration in the ideation phase. In this work, we propose an active learning algorithm leveraging epistemic uncertainty estimation and the recently proposed GFlowNets as a generator of diverse candidate solutions, with the objective to obtain a diverse batch of useful (as defined by some utility function, for example, the predicted anti-microbial activity of a peptide) and informative candidates after each round. We also propose a scheme to incorporate existing labeled datasets of candidates, in addition to a reward function, to speed up learning in GFlowNets. We present empirical results on several biological sequence design tasks, and we find that our method generates more diverse and novel batches with high scoring candidates compared to existing approaches.`,
    authors: `Authors: Moksh Jain, Emmanuel Bengio, Alex-Hernandez Garcia, Jarrid Rector-Brooks, Bonaventure F. P. Dossou, Chanakya
    Ekbote, Jie Fu, Tianyu Zhang, Micheal Kilgour, Dinghuai Zhang, Lena Simine, Payel Das, Yoshua Bengio`,
    image: './images/biological_seq_design.jpeg',
    tags: [
      'ICML, 2022','Generative Flow Networks', 'Drug Discovery', 'Energy-Based Models'],
    paper: 'https://arxiv.org/abs/2203.04115'
  },
  {
    id: 4,
    title:
      'Optimal Deployment Strategy for Relay Based UAV Assisted Cooperative Communication for Emergency Applications',
    description: `There has been a lot of research in the last decade on UAV assisted wireless communication networks. Due to its ability of fast deployment, it is seen as a potential solution to establish communication under emergency scenarios like natural disasters. The mobile nature of the UAVs offers a lot of flexibility, which can be harnessed to improve the QoS of a wireless communication network. In this paper UAV assisted cooperative communication to serve different user clusters distributed in a geographical location is considered. These user clusters do not have access to any conventional base station which is typically a scenario under natural disasters. Each cluster is served by two types of UAVs: cluster UAV which hovers on the top of the cluster centroid and relay UAV which relays information between a central base station (CBS) and cluster UAV. To achieve the required QoS, which is serving a maximum number of users with limited available power, two major parameters have to be optimized apart from other parameters. These are the height of the cluster UAV and trajectory of the relay UAV. To solve this problem, a three-step approach is considered in this paper. In the first step, an unsupervised learning algorithm is used to find the horizontal location parameters of cluster UAVs. Then using convex optimization to find the optimal height of the cluster UAV under power constraints and capacity requirement. Finally using a heuristic algorithm to find the optimal trajectory with minimum distance to be traveled by the relay UAVs. The wireless channel considered here is a simple line of sight (LoS) with a path loss. Computer simulations are performed to prove the validity of the proposed approach in comparison with random deployment.`,
    image: './images/uav.jpeg',
    authors:
      'Authors: Nelapati Lava Prasad, Chanakya Ekbote, Barathram Ramkumar',
    tags: [
      'NCC 2021', 'Convex Optimisation', 'Unsupervised Learning'],
    code: 'https://github.com/cekbote/optimal_uav_placement_and_communication/tree/main',
    paper: 'https://ieeexplore.ieee.org/abstract/document/9530098'
  },
  {
    id: 5,
    title:
      'Stochastic Game Frameworks for Efficient Energy Management in Microgrid Networks',
    description: `We consider the problem of energy management in microgrid networks. A microgrid is capable of generating power from a renewable resource and is responsible for handling the demands of its dedicated customers. Owing to the variable nature of renewable generation and the demands of the customers, it becomes imperative that each microgrid optimally manages its energy. This involves intelligently scheduling the demands at the customer side, selling (when there is a surplus) and buying (when there is a deficit) the power from its neighboring microgrids depending on its current and future needs. In this work, we formulate the problems of demand and battery scheduling, energy trading and dynamic pricing (where we allow the microgrids to decide the price of the transaction depending on their current configuration of demand and renewable energy) in the framework of stochastic games. Subsequently, we propose a novel approach that makes use of independent learners Deep Q-learning algorithm to solve this problem.`,
    image: './images/microgrid.jpeg',
    authors: `Authors: Chanakya Ekbote*, Shravan Nayak*, Annanya Pratap Singh Chauhan*, Raghuram Bharadwaj Diddigi, Prishita
    Ray, Abhinava Sikdar, Sai Koti Reddy Danda, Shalabh Bhatnagar`,
    tags: [
      'IEEE PES ISGT Europe 2020', 'Reinforcement Learning', 'Microgrids', 'AI For Good'],
    paper: 'https://ieeexplore.ieee.org/document/9248952'
  }
]

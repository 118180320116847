var link = <a href="https://www.youtube.com/watch?v=GWYhtksrmhE&ab_channel=LowLevelLearning">log in</a>;

export const Bio = {
  name: 'Chanakya Ekbote',
  roles: [
    'Machine Learning Researcher',
    'Programmer',
    'Lover of Ice Cream',
    'Philosopher of Shower Thoughts'
  ],

  description_1: `
  I am an incoming Masters Student and Research Scholar at 
  École Polytechnique Fédérale de Lausanne (EPFL). 
  Prior to this, I spent two fantastic years at Microsoft Research India (MSR) 
  as a predoctoral research fellow, advised by Arun Iyer and Sundarajan 
  Sellamanickam. During my time at MSR, I focused on research that 
  combined machine learning and graphs, working on problems related to 
  supervised and unsupervised representation learning on graphs, 
  spectral graph theory, and multi-behavior recommendation systems. 
  My primary interest lies in developing algorithmic and theoretical tools to 
  enhance our understanding of machine learning and make it more robust and 
  practical, especially in the context of graph data.
  `,
  description_2: `  
  In the past, I have worked on various topics at different institutions, 
  including Freie Universität Berlin, Indian Institute of Science (IISc), S20.AI, and as a Google Summer of Code Student Developer. 
  I completed my undergraduate studies at the Indian Institute of Technology 
  (IIT) Bhubaneswar in 2021.
  `,
  description_3: `
  When not staring at code, you can find me either on a basketball court, 
  going for a run, playing the piano, 
  or enjoying hikes. Feel free to reach out if you'd like to discuss any of the above. 
    I'm open to all kinds of potential collaborations and conversations, 
    be it discussions on representation learning theory to why I believe Jordan is the GOAT :).
  `,
  // description_4: `P.S. I am on the lookout for Ph.D positions, both in
  // industry and academia.
  // `,
  github: 'https://github.com/cekbote',
  resume:
    'https://drive.google.com/file/d/1keMGgnar5xnEpGXCS9SMxxVdMV7iv6xE/view?usp=drive_link',
  linkedin: 'https://www.linkedin.com/in/chanakyaekbote/',
  twitter: 'https://twitter.com/thecekbote',
  scholar:
    'https://scholar.google.com/citations?user=Jr2CK6QAAAAJ&hl=en&authuser=1',
  github: 'https://github.com/cekbote',
  email: 'chanakya.ekbote@epfl.ch'
}

import React from 'react'
import {
  Nav,
  NavLink,
  NavbarContainer,
  Span,
  NavLogo,
  NavItems,
  GitHubButton,
  ButtonContainer,
  MobileIcon,
  MobileMenu,
  MobileNavLogo,
  MobileLink
} from './NavbarStyledComponent'
import { DiCssdeck } from 'react-icons/di'
import { FaBars } from 'react-icons/fa'
import { DiUikit } from 'react-icons/di'
import { Bio } from '../../data/hero'
import { Close, CloseRounded } from '@mui/icons-material'
import { useTheme } from 'styled-components'

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const theme = useTheme()
  return (
    <Nav>
      <NavbarContainer>
        <NavLogo to='/'>
          <a
            style={{
              display: 'flex',
              alignItems: 'center',
              color: 'white',
              marginBottom: '20;',
              cursor: 'pointer'
            }}
          >
            <DiUikit size='3rem' /> <Span>Portfolio</Span>
          </a>
        </NavLogo>
        <MobileIcon>
          <FaBars
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          />
        </MobileIcon>
        <NavItems>
          <NavLink href='#about'>About</NavLink>
          {/* <NavLink href='#skills'>Skills</NavLink> */}
          <NavLink href='#experience'>Experience</NavLink>
          <NavLink href='#projects'>Publications</NavLink>
          <NavLink href='#footer'>Contact</NavLink>
          <NavLink href='https://scholar.google.com/citations?user=Jr2CK6QAAAAJ&hl=en&authuser=1'>Scholar</NavLink>
          {/* <NavLink href='#education'>Education</NavLink> */}
        </NavItems>
        <ButtonContainer>
          <GitHubButton href={Bio.resume} target='_blank'>
          Résumé
          </GitHubButton>
        </ButtonContainer>
        {isOpen && (
          <MobileMenu isOpen={isOpen}>
            <MobileLink
              href='#about'
              onClick={() => {
                setIsOpen(!isOpen)
              }}
            >
              About
            </MobileLink>
            <MobileLink
              href='#experience'
              onClick={() => {
                setIsOpen(!isOpen)
              }}
            >
              Experience
            </MobileLink>
            <MobileLink
              href='#projects'
              onClick={() => {
                setIsOpen(!isOpen)
              }}
            >
              Publications
            </MobileLink>
            <MobileLink
              href='#footer'
              onClick={() => {
                setIsOpen(!isOpen)
              }}
            >
              Contact
            </MobileLink>
            <MobileLink
              href='https://scholar.google.com/citations?user=Jr2CK6QAAAAJ&hl=en&authuser=1'
              onClick={() => {
                setIsOpen(!isOpen)
              }}
            >
              Scholar
            </MobileLink>
            <GitHubButton
              style={{
                padding: '10px 16px',
                background: `${theme.primary}`,
                color: 'white',
                width: 'max-content'
              }}
              href={Bio.resume}
              target='_blank'
            >
              Résumé
            </GitHubButton>
          </MobileMenu>
        )}
      </NavbarContainer>
    </Nav>
  )
}

export default Navbar

import React from 'react'
import HeroBgAnimation from '../HeroBgAnimation'
import {
  HeroContainer,
  HeroBg,
  HeroLeftContainer,
  Img,
  HeroRightContainer,
  HeroInnerContainer,
  TextLoop,
  Title,
  Span,
  SubTitle,
  SubTitle2,
  SocialMediaIcons,
  SocialMediaIcon,
  ResumeButton
} from './HeroStyle'
import Typewriter from 'typewriter-effect'
// import { Bio } from '../../data/constants';
import { Bio } from '../../data/hero'
import { Spa } from '@mui/icons-material'

const HeroSection = () => {
  return (
    <div id='about'>
      <HeroContainer>
        <HeroBg>
          <HeroBgAnimation />
        </HeroBg>
        <HeroInnerContainer>
          <HeroLeftContainer id='Left'>
            <Title>
              Hi, I am <Span>{Bio.name}</Span>
            </Title>
            <TextLoop>
              I am a
              <Span>
                <Typewriter
                  options={{
                    strings: Bio.roles,
                    autoStart: true,
                    loop: true
                  }}
                />
              </Span>
            </TextLoop>
            <SubTitle2>
              I am on the <Span>lookout for Ph.D. positions.</Span>
            </SubTitle2>
            <SubTitle>  I am currently a Masters Student 
              and <a href="https://www.epfl.ch/schools/ic/education/master/research-scholars/" target="_blank">
                <Span>Research Scholar</Span></a> at <Span>École Polytechnique Fédérale de Lausanne (EPFL)</Span>, working at the 
              intersection of <Span>graph machine learning and generative models</Span>, 
              with <a href="https://people.epfl.ch/maria.brbic" target="_blank">
                <Span>Prof. Maria Brbic</Span></a>.  
              Before this, I had the privilege of spending two exciting years as 
              a <a href="https://www.microsoft.com/en-us/research/academic-program/research-fellows-program-at-microsoft-research-india/"><Span>Predoctoral Research Fellow</Span></a> at <Span>Microsoft Research India (MSR)</Span>, 
              where I was under the guidance 
              of <a href="https://www.microsoft.com/en-us/research/people/ariy/" target="_blank">
                <Span>Arun Iyer</Span></a> and <a href="https://scholar.google.com/citations?user=JOk66doAAAAJ&hl=en" target="_blank">
                <Span>Sundarajan Sellamanickam</Span></a>. 
              During my time at MSR, my research was centered around the <Span>integration 
              of machine learning and graph-related problems, including supervised and 
              unsupervised representation learning on graphs, spectral graph theory, and 
              multi-behavior recommendation systems</Span>. I also had the opportunity to intern 
              at <Span>Mila - Quebec AI Institute</Span>, where I contributed to the 
              <Span> development of GFlowNets for the discovery of new drugs and vaccines</Span>. 
              My work has been presented at conferences such as <Span>NeurIPS, ICLR, ICML, KDD, ECML PKDD</Span> and others.</SubTitle>
            <SubTitle>{Bio.description_2}</SubTitle>
            <SubTitle>{Bio.description_3}</SubTitle>
            <SubTitle>{Bio.description_4}</SubTitle>
            {/* <ResumeButton href={Bio.resume} target='display'>
              Check Resume
            </ResumeButton> */}
          </HeroLeftContainer>
        </HeroInnerContainer>
      </HeroContainer>
    </div>
  )
}

export default HeroSection
